<template>
  <div class="Home">
    <main class="container">
      <link-component></link-component>

      <!-- <enquete-component></enquete-component> -->
      <div class="row g-5">
        <div class="col-md-4">
          <h4 class="pb-4 mb-4 fst-italic border-bottom">
            Santa Helena em Números
          </h4>

          <div v-for="(estudosHome, index) in estudoList" :key="estudosHome.id">
            <div v-if="categoriaStatus(estudosHome.categoria_id)">
              <article
                class="blog-post p-3 mb-3 bg-light rounded"
                v-if="index == 0"
              >
                <img
                  v-if="estudosHome.image_path"
                  :src="urlImgEstudo + estudosHome.id"
                  class="img-fluid rounded"
                  :alt="estudosHome.titulo"
                  :title="estudosHome.titulo"
                />
                <img
                  v-else
                  src="../../assets/images/semimagem.jpg"
                  class="img-fluid rounded"
                  :alt="estudosHome.titulo"
                  :title="estudosHome.titulo"
                />
                <h4 class="fst-italic mt-2">{{ estudosHome.titulo }}</h4>

                <div class="row">
                  <div class="col-6">
                    <p class="blog-post-meta" v-if="moment">
                      <i class="bi bi-calendar3"></i>
                      {{
                        moment(estudosHome.updated_at).format("DD MMMM YYYY")
                      }}
                    </p>
                  </div>
                  <div class="col-6 text-end">
                    <a :href="'/category/' + estudosHome.categoria_id"
                      ><span
                        class="badge bg-dark bg-gradient rounded-pill"
                        style="font-size: 0.84em"
                        >{{ categoriaName(estudosHome.categoria_id) }}</span
                      ></a
                    >
                  </div>
                </div>
                <span v-html="estudosHome.subtitulo"></span>
                <!-- <span v-html="estudosHome.conteudo"></span> -->

                <nav class="blog-pagination mt-2" aria-label="Pagination">
                  <a
                    class="btn btn-outline-primary"
                    :href="'/ler/estudo/' + estudosHome.id"
                    >Saiba mais...</a
                  >
                </nav>
              </article>

              <div
                v-if="index > 0"
                class="d-flex text-muted pt-3 p-3 bg-light rounded"
              >
                <img
                  v-if="estudosHome.image_path"
                  :src="urlImgEstudo + estudosHome.id"
                  class="img-fluid rounded me-2"
                  :alt="estudosHome.titulo"
                  :title="estudosHome.titulo"
                  style="width: 48px; height: 48px"
                />
                <img
                  v-else
                  src="../../assets/images/semimagem.jpg"
                  class="img-fluid rounded me-2"
                  :alt="estudosHome.titulo"
                  :title="estudosHome.titulo"
                  style="width: 48px; height: 48px"
                />
                <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                  <div class="text-dark fw-bold" style="font-size: 1.1em">
                    {{ estudosHome.titulo }}
                  </div>

                  <span v-html="estudosHome.subtitulo"></span>
                  <div class="row">
                    <div class="col-6">
                      <p class="blog-post-meta" v-if="moment">
                        <i class="bi bi-calendar3"></i>
                        {{
                          moment(estudosHome.updated_at).format("DD MMMM YYYY")
                        }}
                      </p>
                    </div>
                    <div class="col-6 text-end">
                      <a :href="'/category/' + estudosHome.categoria_id"
                        ><span
                          class="badge bg-dark bg-gradient rounded-pill"
                          style="font-size: 0.8em"
                          >{{ categoriaName(estudosHome.categoria_id) }}</span
                        ></a
                      >
                    </div>
                  </div>
                  <nav class="blog-pagination" aria-label="Pagination">
                    <a
                      class="btn btn-outline-primary btn-sm"
                      :href="'/ler/estudo/' + estudosHome.id"
                      >Saiba Mais...</a
                    >
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <!-- categorias Listar -->
          <h5 class="mt-3">Leia os estudo por categoria:</h5>
          <category-component style="font-size: 1.2em"></category-component>
        </div>

        <div class="col-md-5">
          <h4 class="pb-4 mb-4 fst-italic border-bottom">
            Observatório Social
          </h4>
          <!-- <div v-for="artigosHome in artigoList" :key="artigosHome.id">
            <article class="blog-post p-4 mb-3 bg-light rounded">
              <img
                v-if="artigosHome.image_path"
                :src="urlImgArtigo + artigosHome.id"
                class="img-fluid rounded"
                :alt="artigosHome.titulo"
                :title="artigosHome.titulo"
              />
              <img
                v-else
                src="../../assets/images/semimagem.jpg"
                class="img-fluid rounded"
                :alt="artigosHome.titulo"
                :title="artigosHome.titulo"
              />
              <h4 class="fst-italic mt-2">
                {{ artigosHome.titulo }}
              </h4>
              <p class="blog-post-meta" v-if="moment">
                <i class="bi bi-calendar3"></i>
                {{ moment(artigosHome.created_at).format("DD MMMM YYYY") }}
              </p>

              <span v-html="artigosHome.conteudo"></span>

              <nav class="blog-pagination mt-2" aria-label="Pagination">
                <a
                  class="btn btn-outline-primary"
                  :href="'/ler/artigo/' + artigosHome.id"
                  >Saiba mais...</a
                >
              </nav>
            </article>
          </div> -->

          <div v-for="(artigosHome, index) in artigoListHome" :key="artigosHome.id">
            <article
              v-if="index == 0"
              class="blog-post p-3 mb-3 bg-light rounded"
            >
              <img
                v-if="artigosHome.image_path"
                :src="urlImgArtigo + artigosHome.id"
                class="img-fluid rounded"
                :alt="artigosHome.titulo"
                :title="artigosHome.titulo"
              />
              <img
                v-else
                src="../../assets/images/semimagem.jpg"
                class="img-fluid rounded"
                :alt="artigosHome.titulo"
                :title="artigosHome.titulo"
              />
              <h4 class="fst-italic mt-2">
                {{ artigosHome.titulo }}
              </h4>
              <p class="blog-post-meta" v-if="moment">
                <i class="bi bi-calendar3"></i>
                {{ moment(artigosHome.created_at).format("DD MMMM YYYY") }}
              </p>

              <span v-html="artigosHome.subtitulo"></span>

              <enquete-component></enquete-component>

              <nav class="blog-pagination mt-2" aria-label="Pagination">
                <a
                  class="btn btn-outline-primary"
                  :href="'/ler/artigo/' + artigosHome.id"
                  >Saiba mais...</a
                >
              </nav>
            </article>

            <div
              v-if="index > 0"
              class="d-flex text-muted pt-3 p-3 bg-light rounded"
            >
              <img
                v-if="artigosHome.image_path"
                :src="urlImgArtigo + artigosHome.id"
                class="img-fluid rounded me-2"
                :alt="artigosHome.titulo"
                :title="artigosHome.titulo"
                style="width: 48px; height: 48px"
              />
              <img
                v-else
                src="../../assets/images/semimagem.jpg"
                class="img-fluid rounded me-2"
                :alt="artigosHome.titulo"
                :title="artigosHome.titulo"
                style="width: 48px; height: 48px"
              />
              <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                <div class="d-flex justify-content-between">
                  <strong class="text-dark fw-bold" style="font-size: 1.1em">{{
                    artigosHome.titulo
                  }}</strong>
                </div>

                <span v-html="artigosHome.subtitulo"></span>
                <p class="blog-post-meta" v-if="moment">
                  <i class="bi bi-calendar3"></i>
                  {{ moment(artigosHome.created_at).format("DD MMMM YYYY") }}
                </p>

                <nav class="blog-pagination mt-2" aria-label="Pagination">
                  <a
                    class="btn btn-outline-primary btn-sm"
                    :href="'/ler/artigo/' + artigosHome.id"
                    >Saiba Mais...</a
                  >
                </nav>
              </div>
            </div>
          </div>
          <div class="row p-3">
            <a class="btn btn-primary mt-0" href="/artigos/"
              >Ver todos os Artigos <i class="bi bi-list"></i
            ></a>
          </div>
        </div>

        <div class="col-md-3">
          <h4 class="pb-4 mb-4 fst-italic border-bottom">Portal da Transparência</h4>
          <div v-for="(notasHome, index) in notadiariaList" :key="notasHome.id">
            <article
              v-if="index == 0"
              class="blog-post p-3 mb-3 bg-light rounded"
            >
              <img
                v-if="notasHome.image_path"
                :src="urlImgNotas + notasHome.id"
                class="img-fluid rounded"
                :alt="notasHome.titulo"
                :title="notasHome.titulo"
              />
              <img
                v-else
                src="../../assets/images/semimagem.jpg"
                class="img-fluid rounded"
                :alt="notasHome.titulo"
                :title="notasHome.titulo"
              />
              <h4 class="fst-italic mt-2">
                <b>{{ notasHome.titulo }}</b>
              </h4>
              

              <!-- <span v-html="notasHome.subtitulo"></span> -->
              <span v-html="notasHome.conteudo"></span>
              <span style="font-size: 12px;" class="blog-post-meta" v-if="moment">
                <i class="bi bi-calendar3"></i>
                {{ moment(notasHome.created_at).format("DD MMMM YYYY") }}
              </span>
              <!-- <nav class="blog-pagination mt-2" aria-label="Pagination">
                <a
                  class="btn btn-outline-primary"
                  :href="'/ler/nota/' + notasHome.id"
                  >Saiba mais...</a
                >
              </nav> -->
            </article>

            <div
              v-if="index > 0"
              class="blog-post p-3 mb-3 bg-light rounded"
            >
              <img
                v-if="notasHome.image_path"
                :src="urlImgNotas + notasHome.id"
                class="img-fluid rounded"
                :alt="notasHome.titulo"
                :title="notasHome.titulo"                
              />
              <img
                v-else
                src="../../assets/images/semimagem.jpg"
                class="img-fluid rounded"
                :alt="notasHome.titulo"
                :title="notasHome.titulo"                
              />

              <div class="lh-sm w-100">
                <div class="d-flex justify-content-between">
                  <strong class="text-dark fw-bold" style="font-size: 1.1em">{{
                    notasHome.titulo
                  }}</strong>
                </div>

                <!-- <span v-html="notasHome.subtitulo"></span> -->
                <span style="color: #333333;" v-html="notasHome.conteudo"></span>
                <span style="font-size: 12px;" class="blog-post-meta" v-if="moment">
                  <i class="bi bi-calendar3"></i>
                  {{ moment(notasHome.created_at).format("DD MMMM YYYY") }}
                </span>
                <!-- <nav class="blog-pagination mt-2" aria-label="Pagination">
                  <a
                    class="btn btn-outline-primary btn-sm"
                    :href="'/ler/nota/' + notasHome.id"
                    >Saiba Mais...</a
                  >
                </nav> -->
              </div>
            </div>
          </div>
          <!-- <div class="row p-3">
            <a class="btn btn-primary mt-0" href="/notas/"
              >Ver todos <i class="bi bi-list"></i
            ></a>
          </div> -->
        </div>
      </div>

      

      <apoio-component></apoio-component>
    </main>
  </div>
</template>

<script>
import { URL_BASE } from "../../services/Commons";
import ApoioService from "../../services/apoio.service";
import ArtigoService from "../../services/artigo.service";
import CategoriaService from "../../services/categoria.service";
import EnqueteService from "../../services/enquete.service";
import EstudoService from "../../services/estudo.service";
import NotaDiariaService from "../../services/notadiaria.service";
import apoioComponent from "../../components/apoioComponent.vue";
import enqueteComponent from "../../components/enqueteComponent.vue";
import linkComponent from "../../components/linkComponent.vue";
import categoryComponent from "../../components/categoryComponent.vue";
import moment from "moment";
import { event } from "vue-gtag";
export default {
  name: "Home",
  data: () => ({
    categoriaList: [],
    apoioList: [],
    artigoListHome: [],
    enqueteList: [],
    estudoList: [],
    notadiariaList: [],
    urlProdHome: "",
    urlBase: "",
    urlImgEstudo: "",
    urlImgArtigo: "",
    urlImgNotas: "",
    moment: null,
  }),
  components: {
    apoioComponent,
    linkComponent,
    categoryComponent,
    enqueteComponent,
  },
  mounted() {
    event("entrou_home", { method: "Google" });
    this.urlBase = URL_BASE;
    this.urlImgEstudo = URL_BASE + `estudodowimagem/`;
    this.urlImgArtigo = URL_BASE + `artigodowimagem/`;
    this.urlImgNotas = URL_BASE + `notadiariadowimagem/`;

    this.moment = moment;
    this.moment.locale("pt-br");

    this.reload();
  },
  methods: {
    categoriaName(idcateg) {
      let retVal = "";
      this.categoriaList.forEach((categ) => {
        if (categ.id == idcateg) {
          retVal = categ.titulo;
        }
      });
      return retVal;
    },

    categoriaStatus(idcateg) {
      let retStat = "";
      this.categoriaList.forEach((categ) => {
        if (categ.id == idcateg) {
          retStat = categ.status;
        }
      });
      return retStat;
    },

    reload() {
      CategoriaService.getAll().then((result) => {
        if (result.status < 400) {
          this.categoriaList = result.data;
        }
      });
      ApoioService.getAll().then((result) => {
        if (result.status < 400) {
          this.apoioList = result.data;
        }
      });
      ArtigoService.getAll().then((result) => {
        if (result.status < 400) {
          this.artigoList = result.data;
        }
      });
      EnqueteService.getAll().then((result) => {
        if (result.status < 400) {
          this.enqueteList = result.data;
        }
      });
      EstudoService.getPaginacao(10).then((result) => {
        if (result.status < 400) {
          this.estudoList = result.data;
        }
      });
      ArtigoService.getPaginacao(10).then((result) => {
        if (result.status < 400) {
          this.artigoListHome = result.data;
        }
      });
      NotaDiariaService.getPaginacao(10).then((result) => {
        if (result.status < 400) {
          this.notadiariaList = result.data;
        }
      });
    },
  },
};
</script>

<style>
</style>
